  <template>
    <div class="" v-if="isLoaded">
      <student-header />
      <div class="bottom-margin pt-5">
        <transition name="modal">
          <div class="modal-mask">
            <div class="modal-wrapper">
              <div class="modal-dialog">
                <div class="modal-content">
                  <div class="modal-body">
                    <h1>YOU ARE NOW UNLOCKING</h1>
                    <h3 class="modal-sub-header">Excelerate Career Matching</h3>
                    <img src="~@/assets/unlock.png" class="my-3" alt="" srcset="">
                    <p>
                      Add <span class="text-background">industries</span> and <span class="text-background">positions</span> you want to work in.
                    </p>
                    <div class="text-right button_bottom">
                      <router-link
                        class="btn btn-white w-200 mt-4 Talent-UnlockCareerMatching-Next"
                        to="/user/student-talent/industry"
                      >
                        Next
                      </router-link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </transition>
      </div>
    </div>
  </template>

  <script>
  import { mapState } from 'vuex';
  import StudentHeader from '../../components/student/StudentHeader.vue';
  export default {
    components: { StudentHeader },
    data() {
      return {
        userDetails: null,
      }
    },
    computed: {
      ...mapState(['user']),
      isLoaded() {
        var vm = this;
        var result = false;
        if (this.user.userId !== null) {
          var userInfo = JSON.parse(JSON.stringify(vm.$store.state.user));
          vm.userDetails = userInfo;
        }
        return true;
      },
    },
  };
  </script>

  <style scoped>
  .modal-mask {
    text-align: center;
    width: 100%;
    height: 100%;
    display: table;
    transition: opacity 0.3s ease;
  }

  .modal-wrapper {
    display: table-cell;
    vertical-align: middle;
  }
  .modal-dialog {
    max-width: 550px !important;
  }
  .modal-content {
    background: transparent linear-gradient(270deg, #bc1e73 0%, #5a2871 100%) 0%
      0% no-repeat padding-box;
    color: white;
    max-width: 600px;
    padding: 3%;
    border-radius: 10px;
  }
  .modal-sub-header{
    font-size: 24px!important;
  }
  .btn-white {
    background-color: #fff;
    color: #5A2871
  }
  .bottom-margin {
    margin-bottom: 130px;
  }
  @media(max-width: 767px) {

      .modal-content {
    background: transparent;
    color: white;
    border:none!important;

  }
  .button_bottom{
    position: fixed;
    bottom: 15px;
    left: 15px;
    right: 15px;
    margin: 0 auto;
    max-width: 100%;
  }
  .modal-body h1 {
    margin-bottom: 50px;
  }
  }
  </style>
  <style >
    @media(max-width: 767px) {
      #user-pages{
      background: transparent linear-gradient(270deg, #BC1E73 0%, #5A2871 100%) 0% 0% no-repeat padding-box;
    min-height: 100vh !important;
    }
    }
  </style>
